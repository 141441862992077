.InitialLoader-logo {
    animation: InitialLoader-logo-spin infinite 20s linear;
    height: 40vmin;
}

.InitialLoader-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    text-align: center;
}

@keyframes InitialLoader-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}